import type {FetchAction} from "@atg-shared/fetch-types";
import type {MatchNumber, MatchRef, Match, MatchStatus} from "./matchTypes";
import type {HarryBetBody} from "./apiTypes/betTypes";
import type {BetChannel, GameType} from "./betTypes";
import type {OfferingMatch} from "./offeringTypes";
import type {Outcomes} from "./outcomeTypes";
import type {
    REQUEST_COUPONS,
    RECEIVE_COUPONS,
    REQUEST_SHARED_COUPON,
    RECEIVE_SHARED_COUPON,
    REQUEST_CREATE_COUPON,
    RECEIVE_CREATE_COUPON,
    RESET_COUPONS,
    REQUEST_SAVE_COUPON,
    RECEIVE_SAVE_COUPON,
    REQUEST_DELETE_COUPON,
    RECEIVE_DELETE_COUPON,
    SAVE_COUPON,
    CREATE_COUPON_FLOW,
    CREATE_COUPON,
    SET_STAKE,
    SET_MODIFIED,
    CLEAR_COUPON,
    DELETE_COUPON,
    DELETE_COUPON_FLOW,
    SET_WILDCARD,
    VALIDATE_COUPON,
    VALIDATE_COUPON_FAILED,
    CLEAR_COUPON_VALIDATION,
    TOGGLE_HARRY_FOR_COUPON,
    SET_HARRY,
    SET_HARRY_STAKE,
    COPY_COUPON,
    SET_COUPON,
    SYNC_ACTIVE_COUPON,
} from "./couponConstants";
import type {Big9State} from "./globalTypes";

export enum CouponType {
    SIMPLE = "simple",
    ADVANCED = "advanced",
}

export type CouponRow = Pick<Match, "number" | "championship" | "startTime" | "kambi"> & {
    couponId: string;
    matchId: string;
    homeTeamName: string;
    awayTeamName: string;
    homeTeamNameLong: string;
    awayTeamNameLong: string;
    homeTeamAbbreviation: string;
    awayTeamAbbreviation: string;
    overUnderLimit: number;
    status?: MatchStatus;
};

export type CouponMatch = Omit<OfferingMatch, "id" | "homeTeam" | "awayTeam"> & {
    couponId: string;
    homeTeamNameLong: string;
    awayTeamNameLong: string;
};

export type CouponMetadata = {
    id: string;
    gameId: string;
    gameType: GameType;
    gameDate: string;
    modified: string;
    isPublic: boolean; // TODO -> Remove
    couponCreator?: string;
    createdAt?: string;
};

export type OngoingCoupon = {
    game: {
        type: string;
        startTime: string;
        tracks: [];
        rowCost: number;
    };
    modified: string;
    gameId: string;
    id: string;
    outcomes: string[];
    betCost: number | string;
    rows: number;
};

export type BaseCouponData = {
    version: number;
    rowCost: number;
    wildcard?: string | null;
    harryStake: number;
    status?: CouponStatus | null;
    modified?: string; // Why not use modified from CouponMetadata? This one is set in the couponReducer "setModified" found it because of an error when cleaning up the types, added the prop to the type instead of removing the prop for now.
    type: CouponType;
};

export enum CouponStatus {
    ValidationFailed,
}

export type RawCouponData = BaseCouponData & {outcomes: Outcomes};

export type ApiCoupon = CouponMetadata & {coupon: RawCouponData};

export type FormattedCouponData = BaseCouponData & {outcomes: string[]};

export type ReduxCoupon = CouponMetadata & {coupon: FormattedCouponData};

export type Coupons = {[key: string]: ReduxCoupon};

export type CouponsState = {data: Coupons};

export type CouponWithMatches = ReduxCoupon & {matches: readonly OfferingMatch[]};

export enum SimpleSelection {
    HOME = "home",
    TIE = "tie",
    AWAY = "away",
    OVER = "over",
    UNDER = "under",
}

export enum AdvancedSelection {
    HOME_OVER = "homeOver",
    HOME_UNDER = "homeUnder",
    TIE_OVER = "tieOver",
    TIE_UNDER = "tieUnder",
    AWAY_OVER = "awayOver",
    AWAY_UNDER = "awayUnder",
}

export type Big9Coupon = {
    id: string;
    gameId: string;
    gameType: GameType;
    gameDate: string;
    modified: string;
    createdAt?: string;
    couponCreator?: string;
    coupon: {
        version: number;
        rowCost: number;
        wildcard?: MatchNumber | null;
        harryStake: number;
        status?: CouponStatus | null; // SHOULD BE REMOVED
        type: CouponType;
        selections: {[matchNumber in MatchNumber]: Big9Selection};
    };
};

export type SP5Coupon = {
    id: string;
    gameId: string;
    gameType: GameType;
    gameDate: string;
    modified: string;
    createdAt?: string;
    couponCreator?: string;
    coupon: {
        version: number;
        rowCost: number;
        wildcard?: MatchNumber | null;
        harryStake: number;
        status?: CouponStatus | null;
        type: CouponType;
        selections: {[matchNumber in MatchNumber]: SP5Selection};
    };
};

export type Coupon = Big9Coupon | SP5Coupon;

export type Big9Selection = {
    flexHarryOpen: boolean;
    matchNumber: MatchNumber;
    matchId: string;
    harryIsDisabled: boolean;
    simple: {
        [SimpleSelection.HOME]: boolean;
        [SimpleSelection.TIE]: boolean;
        [SimpleSelection.AWAY]: boolean;
        [SimpleSelection.OVER]: boolean;
        [SimpleSelection.UNDER]: boolean;
    };
    advanced: {
        [AdvancedSelection.HOME_OVER]: boolean;
        [AdvancedSelection.HOME_UNDER]: boolean;
        [AdvancedSelection.TIE_OVER]: boolean;
        [AdvancedSelection.TIE_UNDER]: boolean;
        [AdvancedSelection.AWAY_OVER]: boolean;
        [AdvancedSelection.AWAY_UNDER]: boolean;
    };
};

export type SP5Selection = {
    flexHarryOpen: false;
    matchNumber: MatchNumber;
    matchId: string;
    harryIsDisabled: true;
    simple: {
        [SimpleSelection.HOME]: boolean;
        [SimpleSelection.TIE]: boolean;
        [SimpleSelection.AWAY]: boolean;
        [SimpleSelection.OVER]: boolean;
        [SimpleSelection.UNDER]: boolean;
    };
    advanced: {
        [AdvancedSelection.HOME_OVER]: boolean;
        [AdvancedSelection.HOME_UNDER]: boolean;
        [AdvancedSelection.TIE_OVER]: boolean;
        [AdvancedSelection.TIE_UNDER]: boolean;
        [AdvancedSelection.AWAY_OVER]: boolean;
        [AdvancedSelection.AWAY_UNDER]: boolean;
    };
};

export type Selection = Big9Selection | SP5Selection;

export type PlaceBetCouponItem = {
    selections: {
        mode: string;
        legs: {[matchNumber in MatchNumber]: string[]};
    };
    nominalStake: number;
};

export type PlaceBetCoupon = {
    reference: string;
    offeringId: string;
    betType: GameType;
    channel: BetChannel;
    coupons: Array<PlaceBetCouponItem>;
    stake: number;
    canceledLegs: Array<string>;
};

export type FlexHarryCoupon = HarryBetBody & {channel: BetChannel};

export const HomeTieAwaySelections = [
    SimpleSelection.HOME,
    SimpleSelection.TIE,
    SimpleSelection.AWAY,
];
export const OverUnderSelections = [SimpleSelection.OVER, SimpleSelection.UNDER];

export const HomeSelections = [AdvancedSelection.HOME_OVER, AdvancedSelection.HOME_UNDER];
export const TieSelections = [AdvancedSelection.TIE_OVER, AdvancedSelection.TIE_UNDER];
export const AwaySelections = [AdvancedSelection.AWAY_OVER, AdvancedSelection.AWAY_UNDER];

export const advancedSelectionGroups = [HomeSelections, TieSelections, AwaySelections];

export const SimpleDisplay = {
    [SimpleSelection.HOME]: "1",
    [SimpleSelection.TIE]: "X",
    [SimpleSelection.AWAY]: "2",
    [SimpleSelection.OVER]: "Över 2.5",
    [SimpleSelection.UNDER]: "Under 2.5",
};

export const SimpleShortDisplay = {
    [SimpleSelection.HOME]: "1",
    [SimpleSelection.TIE]: "X",
    [SimpleSelection.AWAY]: "2",
    [SimpleSelection.OVER]: "Ö",
    [SimpleSelection.UNDER]: "U",
};

export const AdvancedDisplay = {
    [AdvancedSelection.HOME_OVER]: "1Ö",
    [AdvancedSelection.HOME_UNDER]: "1U",
    [AdvancedSelection.TIE_OVER]: "XÖ",
    [AdvancedSelection.TIE_UNDER]: "XU",
    [AdvancedSelection.AWAY_OVER]: "2Ö",
    [AdvancedSelection.AWAY_UNDER]: "2U",
};

export type RowCost = 50 | 100 | 200 | 500 | 1000 | 2000;

export type MatchNumberAndId = Pick<MatchRef, "id" | "number">;

export type FetchCouponCallbackArgs = {
    couponId?: string;
    offeringId?: string;
    matchId?: string;
};

export type FetchCouponsAction = FetchAction<
    typeof REQUEST_COUPONS,
    typeof RECEIVE_COUPONS,
    ApiCoupon[],
    Big9State
>;

export type FetchSharedCouponAction = FetchAction<
    typeof REQUEST_SHARED_COUPON,
    typeof RECEIVE_SHARED_COUPON,
    ApiCoupon
>;

export type CreateCouponRequestAction = FetchAction<
    typeof REQUEST_CREATE_COUPON,
    typeof RECEIVE_CREATE_COUPON,
    ApiCoupon
>;

export type ResetCouponsAction = {
    type: typeof RESET_COUPONS;
};

export type SaveCouponRequest = FetchAction<
    typeof REQUEST_SAVE_COUPON,
    typeof RECEIVE_SAVE_COUPON,
    ApiCoupon
>;

export type DeleteCouponRequest = FetchAction<
    typeof REQUEST_DELETE_COUPON,
    typeof RECEIVE_DELETE_COUPON,
    string
>;

export type ReceiveCouponsAction = {
    type: typeof RECEIVE_COUPONS;
    payload: ApiCoupon[];
    error: boolean;
};

export type ReceiveCreateCouponAction = {
    type: typeof RECEIVE_CREATE_COUPON;
    payload: ApiCoupon;
    error: boolean;
};

export type ReceiveSaveCouponAction = {
    type: typeof RECEIVE_SAVE_COUPON;
    payload: ApiCoupon;
    error: boolean;
};

export type SaveCouponAction = {
    type: typeof SAVE_COUPON;
    payload: {couponId: string};
};

export type CreateCouponFlowAction = {
    type: typeof CREATE_COUPON_FLOW;
    payload: {
        offeringId: string;
        couponId?: string;
    };
};

export type CreateCouponAction = {
    type: typeof CREATE_COUPON;
    payload: {
        coupon: Coupons;
        outcomes: Outcomes;
    };
};

export type SetStakeAction = {
    type: typeof SET_STAKE;
    payload: {couponId: string; stake: number};
};

export type SetModifiedAction = {
    type: typeof SET_MODIFIED;
    payload: {couponId: string; modified: string};
};

export type ClearCouponAction = {
    type: typeof CLEAR_COUPON;
    payload: {couponId: string; offeringId: string};
};

export type DeleteCouponAction = {
    type: typeof DELETE_COUPON;
    payload: {outcomeIds: string[]; couponId: string};
};

export type DeleteCouponSagaAction = {
    type: typeof DELETE_COUPON_FLOW;
    payload: {couponId: string};
};

export type SetWildcardAction = {
    type: typeof SET_WILDCARD;
    payload: {couponId: string; matchId: string | null; oldWildcardId?: string | null};
};

export type ValidateCouponAction = {
    type: typeof VALIDATE_COUPON;
    payload: {couponId: string};
};

export type ValidateCouponFailedAction = {
    type: typeof VALIDATE_COUPON_FAILED;
    payload: {couponId: string};
};

export type ClearCouponValidationAction = {
    type: typeof CLEAR_COUPON_VALIDATION;
    payload: {couponId: string};
};

export type ToggleHarryForCouponAction = {
    type: typeof TOGGLE_HARRY_FOR_COUPON;
    payload: {
        outcomeIds: string[];
        active: boolean;
        couponId: string;
    };
};
export type SetHarryAction = {
    type: typeof SET_HARRY;
    payload: {
        active: boolean;
        couponId: string;
        setAmount: number;
    };
};

export type SetHarryStakeAction = {
    type: typeof SET_HARRY_STAKE;
    payload: {
        couponId: string;
        setAmount: number;
    };
};

export type CopyCouponAction = {
    type: typeof COPY_COUPON;
    payload: {
        original: ApiCoupon;
        useOriginalCouponId?: boolean;
    };
};

export type SetCouponAction = {
    type: typeof SET_COUPON;
    payload: ApiCoupon[];
};

export type SyncActiveCouponAction = {
    type: typeof SYNC_ACTIVE_COUPON;
    payload: ApiCoupon;
};
