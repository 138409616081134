import * as React from "react";
import {useLocation} from "react-router-dom";

export enum Pool {
    Big9 = "BIG9",
    Sp5 = "SP5",
}

export enum PoolNames {
    Big9 = "Big 9",
    Daily5 = "Daily 5",
}

enum Feature {
    Wildcard = "wildcard",
    Harry = "harry",
    GameTips = "gameTips",
    HowBig9Works = "howBig9Works",
    UpcomingOfferings = "upcomingOfferings",
    MultipleLastResults = "multipleLastResults",
    SingleWinner = "singleWinner",
    FiveMatchPool = "fiveMatchPool",
    ShopsQRCode = "shopsQRCode",
}

interface ProductConfig {
    routeName: string;
    baseUrl: string;
    name: string;
    pool: Pool;
    features: Feature[];
}

const productConfigs: ProductConfig[] = [
    {
        routeName: "big9",
        baseUrl: "/sport/big9",
        name: "Big 9",
        pool: Pool.Big9,
        features: [
            Feature.Wildcard,
            Feature.Harry,
            Feature.GameTips,
            Feature.HowBig9Works,
            Feature.SingleWinner,
            Feature.ShopsQRCode,
        ],
    },
    {
        routeName: "daily5",
        baseUrl: "/sport/daily5",
        name: "Daily 5",
        pool: Pool.Sp5,
        features: [
            Feature.UpcomingOfferings,
            Feature.MultipleLastResults,
            Feature.FiveMatchPool,
        ],
    },
];

export function getType(pathname: string = window.location.pathname) {
    const [, , type] = pathname.split("/");

    if (type === "daily5") return Pool.Sp5;
    if (type === "big9") return Pool.Big9;
    if (type === "big9-retail") return Pool.Big9;

    return undefined;
}

export function usePool() {
    const {pathname} = useLocation();

    const activeProduct = React.useMemo(
        () =>
            productConfigs.find((config) => {
                const type = getType(pathname);

                return config.pool === type;
            }),
        [pathname],
    );

    const featureSupport = React.useMemo(
        () =>
            Object.values(Feature).reduce(
                (acc, feature) => ({
                    ...acc,
                    [feature]: activeProduct?.features.includes(feature) || false,
                }),
                {} as Record<Feature, boolean>,
            ),
        [activeProduct],
    );

    return {
        type: activeProduct?.pool,
        name: activeProduct?.name,
        baseUrl: activeProduct?.baseUrl,
        isSp5: activeProduct?.pool === Pool.Sp5,
        featureSupport,
        isBig9: activeProduct?.pool === Pool.Big9,
    };
}
