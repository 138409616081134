import type {Reducer, AnyAction} from "redux";
import type {MatchesState} from "@atg-sport-shared/pool-types";
import type {Offering} from "@atg-sport-shared/pool-types/offeringTypes";
import type {Match} from "@atg-sport-shared/pool-types/matchTypes";
import {
    RECEIVE_OFFERINGS,
    RECEIVE_OFFERING,
    OFFERING_UPDATE,
    type FetchOfferingsAction,
    type FetchOfferingAction,
    type OfferingUpdateAction,
} from "@atg-sport-shared/pool-types";
import type {MatchUpdateAction} from "./matches.actions";
import {MATCH_UPDATE} from "./matches.actions";
import {updateMatchFromLiveData} from "./matches.helpers";

const initialState: MatchesState = {};

const matchesToObject = (matches: ReadonlyArray<Match>) =>
    matches.reduce(
        (previousMatches, currentMatches) => ({
            ...previousMatches,
            [currentMatches.id]: {
                ...currentMatches,
                sportradar: {
                    ...currentMatches.sportradar,
                    matchId: (() => {
                        const eventIdParts =
                            currentMatches.sportradar.eventId?.split(":") ?? [];
                        return eventIdParts.length === 3 ? eventIdParts[2] ?? null : null;
                    })(),
                },
            },
        }),
        {},
    );

export const matchesReducer: Reducer<MatchesState> = (
    state: MatchesState = initialState,
    _action: AnyAction,
) => {
    const action = _action as
        | FetchOfferingsAction
        | FetchOfferingAction
        | OfferingUpdateAction
        | MatchUpdateAction;

    switch (action.type) {
        case RECEIVE_OFFERINGS:
        case RECEIVE_OFFERING: {
            if (
                !action.error &&
                action.payload instanceof Array &&
                action.payload.length
            ) {
                const data: MatchesState = action.payload.reduce(
                    (matches: MatchesState, offering: Offering) => ({
                        ...matches,
                        ...matchesToObject(offering.matches),
                    }),
                    {},
                );
                return {...state, ...data};
            }
            return state;
        }
        case OFFERING_UPDATE: {
            const {
                offering: {matches},
            } = action.payload;

            const updatedMatches = matches.map((newMatchData: Match) => {
                const matchToUpdate: Match | undefined = state[newMatchData.id];

                return {...newMatchData, liveData: matchToUpdate?.liveData};
            });

            return {
                ...state,
                ...matchesToObject(updatedMatches),
            };
        }
        case MATCH_UPDATE: {
            const {type: updateType, match: newMatchData} = action.payload;

            const matchToUpdate: Match | undefined = state[newMatchData.id];

            if (!matchToUpdate) {
                return state;
            }

            const updatedMatch = updateMatchFromLiveData({
                matchToUpdate,
                updateType,
                newMatchData,
            });

            return {
                ...state,
                [matchToUpdate.id]: updatedMatch,
            };
        }

        default: {
            return state;
        }
    }
};
