import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import queryString from "query-string";
import {DEFAULT_OFFERING_SETTINGS} from "@atg-sport-shared/sportsbook-utils-kambi";
import type {LiveEventsResponse} from "./models";

const {baseUrl, offering, baseParams} = DEFAULT_OFFERING_SETTINGS;

export const kambiApi = createApi({
    reducerPath: "kambi-api",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}offering/v2018/${offering}`,
    }),
    endpoints: (builder) => ({
        getLiveEvents: builder.query<LiveEventsResponse, void>({
            query: () => ({
                url: `/event/live/open?${queryString.stringify({
                    ...baseParams,
                    ncid: Date.now(),
                })}`,
            }),
        }),
    }),
});

export const {useGetLiveEventsQuery} = kambiApi;
