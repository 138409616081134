import type {Reducer, AnyAction} from "redux";
import {combineReducers} from "redux";
import * as _FetchReducer from "@atg-shared/fetch-redux";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import type {Receipt} from "@atg-sport-shared/pool-types/betTypes";
import type {FormattedCouponData} from "@atg-sport-shared/pool-types/couponTypes";
import {
    type ReceivePlaceBetAction,
    type RequestPlaceBetAction,
    RECEIVE_PLACE_BET,
    REQUEST_PLACE_BET,
    SET_STAKE,
    type SetStakeAction,
} from "@atg-sport-shared/pool-types";
import type {
    StartPurchaseAction,
    PurchaseDoneAction,
    PurchaseFailedAction,
    PurchaseLoadingAction,
    PurchaseSuccessAction,
    LackingFundsAction,
} from "./purchaseActions";
import {
    START_PURCHASE,
    PURCHASE_DONE,
    PURCHASE_FAILED,
    PURCHASE_LOADING,
    PURCHASE_SUCCESS,
    LACKING_FUNDS,
} from "./purchaseActions";

const FetchReducer = _FetchReducer as any;

type BetActions =
    | RequestPlaceBetAction
    | ReceivePlaceBetAction
    | PurchaseDoneAction
    | PurchaseFailedAction
    | StartPurchaseAction
    | PurchaseLoadingAction
    | PurchaseSuccessAction
    | SetStakeAction
    | LackingFundsAction;

type PlaceBetState = {
    errorMessage?: string;
    data: Receipt;
    status?: "success" | "error" | "loading";
};

const placeBetInitialState: PlaceBetState = FetchReducer.createInitialState({data: {}});

export const placeBetReducer = FetchReducer.createFetchReducer(
    REQUEST_PLACE_BET,
    RECEIVE_PLACE_BET,
    PURCHASE_DONE,
    // @ts-expect-error
    (state: PlaceBetState, {error, payload, type}: BetActions): PlaceBetState => {
        switch (type) {
            case REQUEST_PLACE_BET: {
                return {...state, errorMessage: undefined, status: undefined};
            }
            case RECEIVE_PLACE_BET: {
                if (error) {
                    return {...state, status: "error"};
                }
                if (payload) {
                    const receipt = (
                        payload as {
                            receipts: readonly Receipt[];
                        }
                    ).receipts[0];

                    if (!receipt) {
                        return state;
                    }

                    return {
                        ...state,
                        ...receipt,
                    };
                }
                return state;
            }
            case LACKING_FUNDS:
            case PURCHASE_DONE: {
                return placeBetInitialState;
            }
            case PURCHASE_FAILED: {
                return {
                    ...state,
                    errorMessage: (payload as AtgRequestError)?.message,
                    status: "error",
                };
            }
            case PURCHASE_LOADING: {
                return {
                    ...state,
                    status: "loading",
                    errorMessage: undefined,
                };
            }
            case PURCHASE_SUCCESS: {
                return {
                    ...state,
                    status: "success",
                    errorMessage: undefined,
                };
            }
            case SET_STAKE: {
                // TODO: shouldn't we reset the error status too?
                return {...state, errorMessage: undefined};
            }
            default: {
                return state;
            }
        }
    },
);

const purchaseCouponInitialState = {};

type PurchaseActions = StartPurchaseAction | PurchaseDoneAction;
type StatusState = Partial<FormattedCouponData>;

const coupon: Reducer<StatusState> = (
    state: StatusState = purchaseCouponInitialState,
    _action: AnyAction,
) => {
    const {type, ...action} = _action as PurchaseActions;

    switch (type) {
        case START_PURCHASE:
            return {
                ...state,
                harryStake: action?.payload?.harryStake,
            };

        case PURCHASE_DONE:
            return purchaseCouponInitialState;

        default:
            return state;
    }
};

export const purchaseReducer = combineReducers({
    coupon,
    receipt: placeBetReducer,
});
