import type {Reducer, AnyAction} from "redux";
import {isApp} from "@atg-shared/system";
import * as storage from "@atg-shared/storage";
import {APP_LOCAL_STORAGE_READY} from "@atg-shared/storage/domain/atgStorageActions";
import type {AppLocalStorageReadyAction} from "@atg-shared/storage/domain/atgStorageActions";
import type {
    CouponSettings,
    OngoingMatchSettings,
    Setting,
    SettingsState,
} from "@atg-sport-shared/pool-types/settingsTypes";
import {SettingId} from "@atg-sport-shared/pool-types/settingsTypes";
import {MatchStatus} from "@atg-sport-shared/pool-types/matchTypes";
import type {
    SetCouponSettingsAction,
    SetOngoingMatchSettingsAction,
    SetSortByAction,
    LoadSettingsAction,
} from "./settings.actions";
import {
    SET_COUPON_SETTINGS,
    SET_ONGOING_MATCH_SETTINGS,
    SET_SORT_BY,
    LOAD_SETTINGS,
} from "./settings.actions";
import {PRE_MATCH_SETTINGS_KEY, ONGOING_MATCH_SETTINGS_KEY} from "./settings.saga";

const initialState: SettingsState = {
    coupon: {
        odds: {
            id: SettingId.Odds,
            // Web wants the value to default to true, while app wants the
            /// value to default to false.
            value: false,
            label: "Odds",
            order: 0,
            requiresAuth: false,
        },
        betDistribution: {
            id: SettingId.BetDistribution,
            value: false,
            label: "Spelprocent",
            order: 1,
            requiresAuth: false,
        },
        comment: {
            id: SettingId.MatchComment,
            value: false,
            label: "Matchkommentar",
            order: 2,
            requiresAuth: false,
        },
    },
    ongoingMatch: {
        [SettingId.ShouldHighlighWrongSelelctions]: {
            id: SettingId.ShouldHighlighWrongSelelctions,
            label: "Markera både rätt och fel",
            value: !isApp,
            order: 1,
            requiresAuth: true,
        },
        [SettingId.BetDistribution]: {
            id: SettingId.BetDistribution,
            value: !isApp,
            label: "Spelprocent",
            order: 2,
            requiresAuth: false,
        },
    },
    sortingSettings: {
        selectedSortId: "number",
        options: [
            {
                id: "number",
                label: "Matchnummer",
            },
            {
                id: "startTime",
                label: "Tid",
            },
            {
                id: MatchStatus.ONGOING,
                label: "Live",
            },
            {
                id: MatchStatus.DECIDED,
                label: "Avslutad",
            },
            {
                id: MatchStatus.UPCOMING,
                label: "Ej startat",
            },
        ],
    },
};

type SettingsActions =
    | SetCouponSettingsAction
    | SetOngoingMatchSettingsAction
    | SetSortByAction
    | LoadSettingsAction
    | AppLocalStorageReadyAction;

const applyNewSettings = <T>({
    settings,
    oldState,
}: {
    settings: ReadonlyArray<Setting>;
    oldState: T;
}) =>
    settings.reduce(
        (acc: T, {id, value}: {id: string; value: boolean}) => ({
            ...acc,
            [id]: {...oldState[id as keyof T], value},
        }),
        oldState,
    );

export const settingsReducer: Reducer<SettingsState> = (
    state: SettingsState = initialState,
    _action: AnyAction,
): SettingsState => {
    const action = _action as SettingsActions;

    switch (action.type) {
        case SET_COUPON_SETTINGS:
            return {
                ...state,
                coupon: {
                    ...state.coupon,
                    ...applyNewSettings({
                        settings: action.payload,
                        oldState: state.coupon,
                    }),
                },
            };
        case SET_ONGOING_MATCH_SETTINGS:
            return {
                ...state,
                ongoingMatch: {
                    ...state.ongoingMatch,
                    ...applyNewSettings({
                        settings: action.payload,
                        oldState: state.ongoingMatch,
                    }),
                },
            };
        case SET_SORT_BY:
            return {
                ...state,
                sortingSettings: {
                    ...state.sortingSettings,
                    selectedSortId: action.payload,
                },
            };
        case LOAD_SETTINGS:
        case APP_LOCAL_STORAGE_READY:
            return {
                ...state,
                coupon: {
                    ...state.coupon,
                    ...(storage.getObject(PRE_MATCH_SETTINGS_KEY) as CouponSettings),
                },
                ongoingMatch: {
                    ...state.ongoingMatch,
                    ...(storage.getObject(
                        ONGOING_MATCH_SETTINGS_KEY,
                    ) as OngoingMatchSettings),
                },
            };
        default:
            return state;
    }
};
