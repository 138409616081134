import type {GameType} from "./betTypes";
import type {MatchResult, MatchNumber, Team, Match, MatchRef} from "./matchTypes";

export enum OfferingStatus {
    UPCOMING = "UPCOMING",
    SELL_OPEN = "SELL_OPEN",
    SELL_CLOSED = "SELL_CLOSED",
    DECIDED = "DECIDED",
    UNDER_REVIEW = "UNDER_REVIEW",
    GRADING = "GRADING",
    REVOKING_GRADING = "REVOKING_GRADING",
    CANCELED = "CANCELED",
    GRADED = "GRADED",
    FINALIZED = "FINALIZED",
}

export type MostPlayed = {
    winners: {
        percentage: number;
        selection: MatchResult["winner"];
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
    goals: {
        percentage: number;
        selection: MatchResult["goals"];
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
    wildcards: {
        percentage: number;
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
};

export type Winning = {
    systemCount: number;
    payout: number;
    jackpot?: boolean;
};

export type Winnings = {
    [P in Payout]: Winning;
};

export enum PayoutValue {
    FIVE = "5",
    SEVEN = "7",
    EIGHT = "8",
    NINE = "9",
}

export type Daily5Payout = PayoutValue.FIVE;

export type Big9Payout = PayoutValue.SEVEN | PayoutValue.EIGHT | PayoutValue.NINE;

export const sp5PayoutKeys: Daily5Payout[] = [PayoutValue.FIVE];
export const big9PayoutKeys: Big9Payout[] = [
    PayoutValue.SEVEN,
    PayoutValue.EIGHT,
    PayoutValue.NINE,
];

export type Payout = Daily5Payout | Big9Payout;

export interface CommonPayout {
    pot: number;
    qualifyingUnits: number;
    unitDividend: number;
    jackpot?: boolean;
}

export type Daily5Payouts = {
    [PayoutValue.FIVE]: CommonPayout;
};

export type Big9Payouts = {
    [PayoutValue.SEVEN]: CommonPayout;
    [PayoutValue.EIGHT]: CommonPayout;
    [PayoutValue.NINE]: CommonPayout;
};

export type Payouts = Daily5Payouts | Big9Payouts;

export type Pool = {
    turnover: number | null;
    payouts?: Payouts | null;
    potDistribution?: {[P in Big9Payout]: number} | {[P in Daily5Payout]: number} | null;
    expectedJackpot: number;
    unitStake: number;
    minUnitDividend: number;
    timestamp: string;
    numberOfBets: number;
    numberOfSystems: number;
};

export type Offering = {
    gameType: GameType;
    id: string;
    sport?: string;
    name: string | null;
    description: string | null;
    startTime: string;
    lastUpdate?: string;
    status: OfferingStatus;
    pool: Pool;
    matches: ReadonlyArray<Match>;
    overUnderLimit: number;
    serialNumber: number;
};

export type OfferingMatch = Match & {
    matchId: string;
    overUnderLimit: number;
    homeTeamName: string;
    awayTeamName: string;
    homeTeamAbbreviation: string;
    awayTeamAbbreviation: string;
};

export type OfferingWithMatches = Omit<Offering, "matches"> & {
    matches: ReadonlyArray<OfferingMatch>;
};

export type OfferingWithMatchRefs = Omit<Offering, "matches"> & {
    matches: ReadonlyArray<MatchRef>;
};

export type OfferingsById = {[key: string]: OfferingWithMatchRefs};
