export const RCT_SINGLE = "RCT_SINGLE";
export const RCT_COMBINATION = "RCT_COMBINATION";

export const SPORT_PAGE = {
    POPULAR_EVENTS: "SPORT_PAGE_POPULAR_EVENTS",
    LANDING: "SPORT_PAGE_LANDING",
    LANDING_OLD: "SPORT_PAGE_LANDING_OLD",
    EVENT: "SPORT_PAGE_EVENT",
    LIVE_NOW: "SPORT_PAGE_LIVE_NOW",
    STARTING_WITHIN: "STARTING_WITHIN",
    STARTING_SOON: "STARTING_SOON",
    STREAMING: "STREAMING",
    SPORT: "SPORT_PAGE_SPORT",
    BET_HISTORY: "SPORT_PAGE_BET_HISTORY",
    BET_HISTORY_DETAIL: "SPORT_PAGE_BET_HISTORY_DETAIL",
    BONUS_OFFERS: "SPORT_PAGE_BONUS_OFFERS",
    PARTICIPANT: "SPORT_PAGE_PARTICIPANT",
    LEAGUE: "SPORT_PAGE_LEAGUE",
    UNKNOWN: "SPORT_PAGE_UNKNOWN",
    SPORT_LIST: "SPORT_PAGE_SPORT_LIST",
    NEWS: "NEWS",
    ARTICLE: "ARTICLE",
    TIPS: "NEWS_TIPS",
    TIPS_ARTICLE: "ARTICLE_TIPS",
};

export const TIPS_AND_NEWS_ROUTE = "/sport/tips-och-nyheter" as const;

export const STREAMING_ROUTES = {
    STREAMING_ALL: "filter/all/all/all/all/streaming",
};

export const BET_HISTORY_PAGES = [SPORT_PAGE.BET_HISTORY, SPORT_PAGE.BET_HISTORY_DETAIL];

export const HIJACKED_PAGES = [
    SPORT_PAGE.POPULAR_EVENTS,
    SPORT_PAGE.LANDING,
    SPORT_PAGE.SPORT_LIST,
    SPORT_PAGE.NEWS,
    SPORT_PAGE.TIPS,
    SPORT_PAGE.TIPS_ARTICLE,
    SPORT_PAGE.ARTICLE,
];

export const CASH_OUT_PAGES = [
    SPORT_PAGE.EVENT,
    SPORT_PAGE.LEAGUE,
    SPORT_PAGE.PARTICIPANT,
    SPORT_PAGE.LIVE_NOW,
    SPORT_PAGE.STARTING_WITHIN,
];

export const AUTH_REQUIRED_PAGES = [SPORT_PAGE.BONUS_OFFERS, ...BET_HISTORY_PAGES];

export const BANNER_PAGES = [...CASH_OUT_PAGES, ...AUTH_REQUIRED_PAGES];

export const TAG_TYPE = {
    LIVE: "TAG_TYPE_LIVE",
    SOON: "TAG_TYPE_SOON",
    DEFAULT: "TAG_TYPE_DEFAULT",
};

export const RACING_SPORTS: Array<string> = ["greyhound", "horse_racing"];

export const SPORT_ROOT_PATH = "/sport";
export const SPORT_RECEIPT_PATH = "/konto/mina-spel/sportbok";
export const SPORT_RECEIPT_PATH_OLD = "/konto/mina-spel/sport"; // remove when verticals are using new urls

export const BIG_REDUCED_BETTING = "/sport/big9/reducerat";

export const SEARCH_TYPE_SPORT = "SPORT";
export const SEARCH_TYPE_REGION = "REGION";
export const SEARCH_TYPE_LEAGUE = "LEAGUE";
export const SEARCH_TYPE_PARTICIPANT = "PARTICIPANT";

export const SPORT_TERM_KEYS = {
    AMERICAN_FOOTBALL: "american_football",
    BANDY: "bandy",
    BASEBALL: "baseball",
    BASKTEBALL: "basketball",
    FLOORBALL: "floorball",
    FOOTBALL: "football",
    HANDBALL: "handball",
    ICE_HOCKEY: "ice_hockey",
    TENNIS: "tennis",
    VOLLEYBALL: "volleyball",
    DART: "darts",
    BOXING: "boxing",
    ESPORT: "esports",
    GOLF: "golf",
    TROTTING: "trotting",
    UFC_MMA: "ufc_mma",
};

export const SPORT_CLOUDFRONT = {
    PROD_URL: "https://d2eelhowcp7um3.cloudfront.net/prod",
    STAGE_URL: "https://d2eelhowcp7um3.cloudfront.net/stage",
};
export const SPORTRADAR_MATCH_URL_BASE = "https://s5.sir.sportradar.com/atg/se/match/m";

export const KAMBI_BC_ID = "KambiBC";
export const KAMBI_CONTAINER_ID = "kambi-container";
export const KAMBI_HIDDEN_CONTAINER_ID = "kambi-container-hidden";

export enum ERROR_RESPONSES {
    GEOLOCATION_BLOCKED = "GEOLOCATION_BLOCKED",
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    RGS_TEMPORARY_SELF_EXCLUSION = "RGS_TEMPORARY_SELF_EXCLUSION",
    INTERNAL_SERVICE_ERROR = "INTERNAL_SERVICE_ERROR",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED = "RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED",
}
