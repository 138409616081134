import type {Reducer, AnyAction} from "redux";
import type {MatchClockState} from "@atg-sport-shared/pool-types";
import {MatchClockStatus} from "@atg-sport-shared/pool-types/matchTypes";
import type {
    AddMatchClockAction,
    StartMatchClockAction,
    StopMatchClockAction,
    UpdateMatchClockAction,
} from "./matchClockActions";
import {
    ADD_MATCH_CLOCK,
    START_MATCH_CLOCK,
    STOP_MATCH_CLOCK,
    UPDATE_MATCH_CLOCK,
} from "./matchClockActions";

const initialState: MatchClockState = {};

export const matchClockReducer: Reducer<MatchClockState> = (
    state: MatchClockState = initialState,
    _action: AnyAction,
) => {
    const action = _action as
        | StartMatchClockAction
        | UpdateMatchClockAction
        | StopMatchClockAction
        | AddMatchClockAction;

    switch (action.type) {
        case START_MATCH_CLOCK: {
            const {matchId, minute = 0} = action.payload;

            const newClock = {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    minute,
                    status: MatchClockStatus.Ongoing,
                    period: minute > 0 ? 2 : 1,
                },
            };

            return newClock;
        }
        case UPDATE_MATCH_CLOCK: {
            const {matchId, minute} = action.payload;

            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    minute,
                },
            };
        }
        case STOP_MATCH_CLOCK: {
            const {matchId} = action.payload;

            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    status: MatchClockStatus.Stopped,
                },
            };
        }
        case ADD_MATCH_CLOCK: {
            const {matchId, minute} = action.payload;

            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    minute,
                    period: 1,
                    status: MatchClockStatus.Stopped,
                },
            };
        }
        default: {
            return state;
        }
    }
};
