import type {Reducer, AnyAction} from "redux";
import type {MatchComments} from "@atg-sport-shared/pool-types/matchTypes";
import type {StatisticsState} from "@atg-sport-shared/pool-types/statisticsTypes";
import type {
    ReceiveMatchCommentsAction,
    ReceivePreMatchStatisticsAction,
} from "./statistics.actions";
import {RECEIVE_MATCH_COMMENTS, RECEIVE_PRE_MATCH_STATISTICS} from "./statistics.actions";

const initialState: StatisticsState = {
    matches: {},
    leagues: {},
    teams: {},
    matchComments: {},
};

export const statisticsReducer: Reducer<StatisticsState> = (
    state: StatisticsState = initialState,
    _action: AnyAction,
) => {
    // Redux typescript issue, fixed in https://github.com/reduxjs/redux/pull/3679
    // TODO: Remove AnyAction type when Redux has been updated
    const {type, payload} = _action as
        | ReceivePreMatchStatisticsAction
        | ReceiveMatchCommentsAction;
    switch (type) {
        case RECEIVE_PRE_MATCH_STATISTICS: {
            const statisticsPayload = payload as StatisticsState;

            return {
                ...state,
                matches: {
                    ...state.matches,
                    ...statisticsPayload.matches,
                },
                leagues: {
                    ...state.leagues,
                    ...statisticsPayload.leagues,
                },
                teams: {
                    ...state.teams,
                    ...statisticsPayload.teams,
                },
            };
        }

        case RECEIVE_MATCH_COMMENTS: {
            if (payload) {
                return {...state, matchComments: payload as MatchComments};
            }

            return state;
        }
        default:
            return state;
    }
};
