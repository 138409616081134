import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import * as api from "@atg-sport-shared/pool-data-access-service-api";
import type {Big9State} from "@atg-sport-shared/pool-types";
import type {MatchComments} from "@atg-sport-shared/pool-types/matchTypes";
import type {StatisticsState} from "@atg-sport-shared/pool-types/statisticsTypes";

export const REQUEST_PRE_MATCH_STATISTICS = "big9/REQUEST_PRE_MATCH_STATISTICS";
export const RECEIVE_PRE_MATCH_STATISTICS = "big9/RECEIVE_PRE_MATCH_STATISTICS";
export const REQUEST_MATCH_COMMENTS = "big9/REQUEST_MATCH_COMMENTS";
export const RECEIVE_MATCH_COMMENTS = "big9/RECEIVE_MATCH_COMMENTS";

export type FetchPreMatchStatisticsAction = FetchAction<
    typeof REQUEST_PRE_MATCH_STATISTICS,
    typeof RECEIVE_PRE_MATCH_STATISTICS,
    Exclude<StatisticsState, "matchComments">,
    Big9State
>;

export type ReceivePreMatchStatisticsAction = {
    type: typeof RECEIVE_PRE_MATCH_STATISTICS;
    payload: Exclude<StatisticsState, "matchComments">;
};

export const fetchPreMatchStatistics = (
    offeringId: string,
): FetchPreMatchStatisticsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PRE_MATCH_STATISTICS,
        receiveAction: RECEIVE_PRE_MATCH_STATISTICS,
        callApi: call(api.fetchPreMatchStatistics, offeringId),
    },
});

export type ReceiveMatchCommentsAction = {
    type: typeof RECEIVE_MATCH_COMMENTS;
    payload: MatchComments;
};

export type FetchMatchCommentsAction = FetchAction<
    typeof REQUEST_MATCH_COMMENTS,
    typeof RECEIVE_MATCH_COMMENTS,
    MatchComments
>;

export const fetchMatchComments = (): FetchMatchCommentsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_MATCH_COMMENTS,
        receiveAction: RECEIVE_MATCH_COMMENTS,
        callApi: call(api.fetchMatchComments),
    },
});
