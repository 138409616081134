import configureStore from "@atg-shared/micro-frontend/configureStore";
import {frameActionMiddleware} from "atg-store-addons";
import createReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import {kambiApi} from "@atg-sport-shared/api";

const STORE_NAME = "atg-sportsbook";

// Generally speaking, don't use this store directly – instead use Redux hooks to access the store state, etc.
const {store, filteredActionLog, persistor} = configureStore({
    name: STORE_NAME,
    createReducer,
    middlewares: [frameActionMiddleware, kambiApi.middleware],
});
rootSaga(store);

// eslint-disable-next-line no-underscore-dangle
window._sportsBookStore = store;

export {store, filteredActionLog, persistor};
