import type {Reducer, AnyAction} from "redux";
import dayjs from "dayjs";
// @ts-ignore
import * as _FetchReducer from "@atg-shared/fetch-redux";
import type {BetsFetchState} from "@atg-sport-shared/pool-types";
import {serverTime} from "@atg-shared/server-time";
import type {Receipt, ReceiptAndOffering} from "@atg-sport-shared/pool-types/betTypes";
import {
    type OfferingUpdateAction,
    OFFERING_UPDATE,
    type PlaceBetAction,
    type PlaceBetRequestAction,
    type ReceivePlaceBetAction,
    type ReceiveBetsAction,
    type CorrectBetAction,
    type SetCorrectedBetsAction,
    type RevealBetRowsAction,
    type HighlightBig9BetAction,
    type HighlightBig9OfferingBetAction,
    type UpdateBetsAction,
    type FetchMoreBetsAction,
    type FetchBetsLastPageReachedAction,
    type FetchBetsAction,
    type ReceivePlaceBetSimpleHarryModalAction,
    RECEIVE_PLACE_BET,
    RECEIVE_BETS,
    REQUEST_BETS,
    CORRECT_BET,
    SET_CORRECTED_BETS,
    REVEAL_BET_ROWS,
    SET_HIGHLIGHT_BIG9_BET,
    SET_HIGHLIGHT_BIG9_OFFERING_BET,
    UPDATE_BETS,
    FETCH_MORE_BETS,
    FETCH_BETS_LAST_PAGE_REACHED,
    RECEIVE_PLACE_BET_SIMPLE_HARRY_MODAL,
} from "@atg-sport-shared/pool-types";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {getFirstBetId} from "@atg-sport-shared/pool-util-bets-helpers";

export const MY_BIG9_BETS_CORRECTED_STORAGE_KEY = "myBig9BetsAllCorrected";

const FetchReducer = _FetchReducer as any;
type BetActions =
    | PlaceBetAction
    | PlaceBetRequestAction
    | ReceivePlaceBetAction
    | ReceivePlaceBetSimpleHarryModalAction
    | FetchBetsAction
    | ReceiveBetsAction
    | CorrectBetAction
    | SetCorrectedBetsAction
    | RevealBetRowsAction
    | HighlightBig9BetAction
    | HighlightBig9OfferingBetAction
    | UpdateBetsAction
    | FetchMoreBetsAction
    | FetchBetsLastPageReachedAction
    | {type: typeof LOGOUT_FINISHED}
    | OfferingUpdateAction;

const initialState: BetsFetchState = FetchReducer.createInitialState({
    data: {
        offerings: {},
        receipts: [],
        lastPlacedAtFrom: null,
        lastPlacedAtTo: null,
        isFetchingMore: false,
        isLastPageReached: false,
    },
    correctedBets: {},
    betRevealedRows: {},
    highlightedBet: null,
    highlightedOfferingBets: {},
});

const offeringsAndReceipts = ({
    state,
    payload,
}: {
    state: BetsFetchState;
    payload: {
        offerings: ReceiptAndOffering["offerings"];
        receipts: ReadonlyArray<Receipt>;
    };
}) => ({
    ...state,
    data: {
        ...state.data,
        offerings: {
            ...state.data.offerings,
            ...payload.offerings,
        },
        receipts: {
            ...state.data.receipts,
            ...payload?.receipts?.reduce(
                (prev, curr) => ({
                    ...prev,
                    [getFirstBetId(curr)]: curr,
                }),
                {},
            ),
        },
    },
});

export const betsReducer: Reducer<BetsFetchState> = FetchReducer.createFetchReducer(
    REQUEST_BETS,
    RECEIVE_BETS,
    "",
    (state: BetsFetchState = initialState, _action: AnyAction) => {
        // Redux typescript issue, fixed in https://github.com/reduxjs/redux/pull/3679
        // TODO: Remove AnyAction type when Redux has been updated
        const action = _action as BetActions;
        switch (action.type) {
            case RECEIVE_PLACE_BET:
            case RECEIVE_PLACE_BET_SIMPLE_HARRY_MODAL: {
                if (action.error) {
                    return state;
                }

                const payload = action.payload as {
                    offerings: ReceiptAndOffering["offerings"];
                    receipts: ReadonlyArray<Receipt>;
                };

                return offeringsAndReceipts({state, payload});
            }

            case REQUEST_BETS: {
                if (action.context.isPolling) {
                    return {...state, data: {...state.data, isPolling: true}};
                }

                return state;
            }
            case RECEIVE_BETS: {
                if (action.error) {
                    return state;
                }

                const payload = action.payload as {
                    offerings: ReceiptAndOffering["offerings"];
                    receipts: ReadonlyArray<Receipt>;
                };

                const newState = offeringsAndReceipts({state, payload});
                return {
                    ...newState,
                    data: {
                        ...newState.data,
                        lastPlacedAtFrom: action.context?.placedAtFrom ?? null,
                        lastPlacedAtTo: action.context?.placedAtTo ?? null,
                        isFetchingMore: false,
                        isPolling: false,
                    },
                };
            }
            case FETCH_MORE_BETS: {
                return {...state, data: {...state.data, isFetchingMore: true}};
            }
            case FETCH_BETS_LAST_PAGE_REACHED: {
                return {
                    ...state,
                    data: {...state.data, isFetchingMore: false, isLastPageReached: true},
                };
            }
            case LOGOUT_FINISHED: {
                return initialState;
            }
            case CORRECT_BET: {
                const {betId} = action.payload;
                const today = dayjs(serverTime(false)).format("YYYY-MM-DD");

                const correctedBets = {
                    ...state.correctedBets,
                    [betId]: today,
                };

                return {
                    ...state,
                    correctedBets,
                };
            }
            case SET_CORRECTED_BETS: {
                return {
                    ...state,
                    correctedBets: {
                        ...state.correctedBets,
                        ...action.payload.correctedBets,
                    },
                };
            }
            case REVEAL_BET_ROWS: {
                const {betId, revealedRows} = action.payload;

                const betRevealedRows = {
                    ...state.betRevealedRows,
                    [betId]: revealedRows,
                };

                return {
                    ...state,
                    betRevealedRows,
                };
            }
            case SET_HIGHLIGHT_BIG9_BET: {
                const {betId} = action.payload;

                return {
                    ...state,
                    highlightedBet: betId,
                };
            }
            case SET_HIGHLIGHT_BIG9_OFFERING_BET: {
                const {betId, offeringId} = action.payload;

                return {
                    ...state,
                    highlightedOfferingBets: {
                        ...state.highlightedOfferingBets,
                        [offeringId]: betId,
                    },
                };
            }
            // This is just to update the status of the bet after liveofferingsimulation is finished, can be removed later
            case UPDATE_BETS: {
                const {bets} = action.payload;
                return {
                    ...state,
                    bets,
                };
            }
            case OFFERING_UPDATE: {
                const {offering} = action.payload;

                const offerings = {...state.data.offerings, [offering.id]: offering};

                return {
                    ...state,
                    data: {...state.data, offerings},
                };
            }
            default:
                return state;
        }
    },
    initialState,
);
